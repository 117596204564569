<template>
        <div class="login-page">
                <div class="wrapper">
                <div class="container">

                    <div class="row">
                        <div class="col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-8 offset-md-2">

                            <div class="login__header">

                                <div class="login__header--logo">
                                    <a href="/">
                                        <img src="../../assets/img/logo-login.svg" alt="">
                                    </a>
                                </div>
                                <!-- <div class="login__header--lang">
                                    <div class="header__leng">
                                        <ul>
                                            <li class="header__lang--item"><a href="#">kz</a></li>
                                            <li class="header__lang--item header__lang--active"><a href="#">ru</a></li>
                                            <li class="header__lang--item "><a href="#">en</a></li>
                                        </ul>
                                    </div>

                                </div> -->

                                <div class="login__form">

                                    <form @submit.prevent="register">

                                        <div class="form__block--form">
                                            <div class="form__block--subtitle">
                                                Регистрация
                                            </div>
                                            <div class="row">

                                                <div class="col-lg-12">
                                                    <div class="form__login--line">
                                                        <div class="form__login--input">
                                                            <label>
                                                                <div class="label__login--icon">
                                                                    <img src="../../assets/img/mail_login-icon.svg">
                                                                </div>
                                                                <input type="text" required @input="typeEmail()" class="input-linck" v-model="email" name="email" value="" placeholder="Электронная почта">
                                                            </label>
                                                            <span class="input-required" v-if="emailError">{{emailError}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="form__login--line">
                                                        <div class="form__login--input">
                                                            <label>
                                                                <div class="label__login--icon">
                                                                  
																																		<img
																																			src="../../assets/img/password_login-v-icon.svg"
																																			v-if="inputType"
																																			@click="inputType = false"
																																		/>
																																		<img
																																			src="../../assets/img/password_login-icon2.svg"
																																			v-else
																																			@click="inputType = true"
																																		/>
                                                                </div>
                                                                <input type="password" 
																																v-if="!inputType" @input="typePassword()" 
																																class="input-linck" 
																														v-model="password"  name="password" value="" placeholder="Пароль">

																																<input type="text" 
																																v-else  class="input-linck" v-model="password"  name="password" value="" placeholder="Пароль">
                                                            </label>
                                                            <span class="input-required" v-if="passwordError">{{passwordError}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-lg-12" >
                                                    <div class="form__login--input">
                                                        <button class="input__button" type="submit">Войти</button>
                                                    </div>
                                                </div> -->

                                                <div class="col-lg-12" >
                                                    <div class="form__login--input">
                                                        <button class="input__button" @click="handleSend()">Выбрать сертификат</button>
                                                    </div>
                                                </div>

                                                <p v-if="successMsg">{{successMsg}}</p>
                                                <p v-if="errorMsg">{{errorMsg}}</p>

                                                <div class="col-lg-12"  @click="$router.push('/sign')">
                                                    <div class="form__login--registr">
                                                        <a >Войти в систему</a>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </form>

                                </div>

                            </div>
                        </div>


                    </div>
                </div>
                </div>

                 <!-- <v-snackbar
                    :timeout="-1"
                    v-model="showToggle"
                    absolute
                    bottom
                    :color="messageColor"
                    outlined
                    center
                    >
                    {{message}}

                    <template v-slot:action="{ attrs }">
                        <v-icon
                         :color="messageColor"

                            v-bind="attrs"
                            @click="showToggle = false"
                        >
                        mdi-close
                        </v-icon>
                    </template>
                </v-snackbar> -->

                <toggle :message="message" :showToggle="showToggle" :color="messageColor" />


        </div>
</template>


<script>
import toggle  from '../../components/Toggle';
export default {
    components: {
        toggle
    },
    data () {
        return {
            showToggle: false,
            messageColor:' ',
            message: '',

            email:'',
            password: '',
            showError: false,
            errorMsg: '',
            successMsg: '',
            emailError: '',
            passwordError: '',
            ready: false,
						inputType: false,
        }
    },
    methods: {
        callError() {
            this.showToggle = true;
            this.messageColor = 'red darken-1';
        },
        callSuccess() {
            this.showToggle = true;
            this.messageColor = 'green darken-3';
        },
      
        register(e) {
            e.preventDefault();
        },
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        typePassword() {
            if(this.password) {
                this.passwordError = '';
            }
        },
        typeEmail() {
              if(this.email) {
                this.emailError = '';
            }
        },
        handleSend () {
            if(this.email=='') {
                this.emailError = 'Заполните поле';
            }
            if(this.password=='') {
                this.passwordError = 'Заполните поле';
            }
            if(this.validateEmail(this.email)==false) {
                this.emailError = 'Неверная почта';
            }

            if (this.ready == false) {
                this.message = 'NCLayer не включен';
                this.callError();
            } else {
                this.showError = false
                const data = {
                    module: 'kz.gov.pki.knca.commonUtils',
                    method: "signXml",
                    args: [
                        "PKCS12",
                        "AUTHENTICATION",
                        "<login><sessionid>caacda70-fd36-45ed-8d94-45a88890f83a</sessionid></login>",
                        "", ""
                    ]
                };
                this.connection.send(JSON.stringify(data));
            }
        },
        sendEcp() {
            this.$axios({ 
                method: 'post',
                url: this.$API_URL + this.$API_VERSION + 'register', 
                data: {
                    xmlCMS: this.esp__array.responseObject,
                    email: this.email,
                    password: this.password
                }
            })
            .then((response) => {
                if(response.data.data.token) {
                    this.$toast.open({
                        message: 'Успешно зарегистрирован',
                        type: 'success',
                        position: 'bottom',
                        duration: 4000,
                        queue: true
                    });
                }
                setTimeout(() => this.$router.push('/sign'), 1500);                
            })
            .catch((error) => {
                if(error.response.status==451) {
                    this.$toast.open({
                        message: error.response.data.message,
                        type: 'error',
                        position: 'bottom',
                        duration: 4000,
                        queue: true
                    });
                }
                if(error.response.status==403) {
                    this.$toast.open({
                        message: error.response.data.message,
                        type: 'error',
                        position: 'bottom',
                        duration: 4000,
                        queue: true
                    });
                }
            }); 
        },

    },
    created () {
        this.connection = new WebSocket("wss://127.0.0.1:13579/")
        this.connection.onopen = () => {
       
            this.ready = true
        }
        this.connection.onmessage = (e) => {
            const arr = JSON.parse(e.data);
            const parse__arr = arr
            this.esp__array = parse__arr; 
            if (typeof this.esp__array !== 'undefined') {
                this.sendEcp();
            }
        };
    },
    mounted() {
     
    },
    watch: {
      
    }
}
</script>


<style>

</style>